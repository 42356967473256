import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { DataTable } from 'react-native-paper';
import Theme from '../../components/Theme';
import { AuthContext } from '../../Context/AuthContext';
import CustomButton from '../../components/CustomButton';
import React, { useContext, useState, useEffect } from 'react';

const Toc = ({navigation}) => {
  const removeCssStyles = async (htmlContent) => {
    // Remove style attributes from HTML tags
    htmlContent = htmlContent.replace(/style="[^"]*"/g, '');
  
    return htmlContent;
  };

  const htmlToReactNative = async (htmlContent) => {
    var modifiedString = htmlContent.replace(/<h6 >/g, "-");
    var modifiedString1 = modifiedString.replace(/<h6>/g, "-");
    var modifiedString2 = modifiedString1.replace(/<\/h6>/g, "-");
    var modifiedString3 = modifiedString2.replace(/<\/p>/g, "-");
    var modifiedString4 = modifiedString3.replace(/<p>/g, "-");
    var modifiedString5 = modifiedString4.replace(/<ul>/g, "-");
    var modifiedString6 = modifiedString5.replace(/<\/ul>/g, "-");
    var modifiedString7 = modifiedString6.replace(/<li>/g, "-");
    var modifiedString8 = modifiedString7.replace(/<\/li>/g, "-");
    var modifiedString9 = modifiedString8.replace(/<div>/g, "-");
    var modifiedString10 = modifiedString9.replace(/<\/div>/g, "-");
    return modifiedString10
  };


  const {userInfo, logout, isLoading} = useContext(AuthContext);
  const [about, setAbout] = useState(null)
  const fetchAbout = () => {
    fetch(`https://backend.carologyauctions.net/toc`)
      .then(response => {
        return response.json()
      })
      .then(async data => {
        const test = await removeCssStyles(data.data[0]["content"])
        const test2 = await htmlToReactNative(test)

        setAbout(test2)
      })
}

  useEffect(()=>{
    fetchAbout()
  },[])
  return (
    <ScrollView style={styles.root}>
      <Text style={styles.header}>Terms and Condition</Text>
      {about ? <Text>
          {about}
          </Text> : null}
      <View style={{marginBottom: 50}}>

      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  root: {
    padding: 20,
    backgroundColor: 'white',
    width: "100%",
  },
  bullet: {
    fontSize: Theme.fontSize.text,
  },
  title: {
    fontSize: Theme.fontSize.title,
    textAlign: 'left',
    width: '100%',
    marginTop: 10,
    fontWeight: 'bold'
  },
  header: {
    fontSize: 18,
    color: Theme.colors.primary
  },
})

export default Toc