import React from 'react';
import { Text } from 'react-native-paper';

import { View, CheckBox } from 'react-native';

const Checkb = ({ placeholder, value, setValue }) => {
    const [checked, setChecked] = React.useState(false);
  return (
    <View style={{flexDirection: 'row'}}>
      <CheckBox
            style={{backgroundColor: 'white', marginTop: 8}}
            disabled={false}
            value={value}
            onValueChange={(newValue) => setValue(newValue)}
        />
        <Text style={{paddingTop: 8, paddingHorizontal: 10}}>{placeholder}</Text>
    </View>
  );
};

export default Checkb;