import { View, Text } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Title, Paragraph } from 'react-native-paper';

const CustomClassified = ({data, navigation, edit}) => {
  const [mileage, setMileage] = useState(true)
  useEffect(()=>{
    const isJapaneseOrKorean = data?.Specs  === "Japanese" || data?.Specs  === "Korean" || data?.Specs  === "GCC" || data?.Specs === "" || !data?.Specs ;
    isJapaneseOrKorean ? setMileage(true) : setMileage(false)
  },[])


  return (
    <Card style={{ fontFamily: 'sans-serif', width: 170, margin: 10 }} onPress={() => navigation.navigate('ClassifiedVehicle', {id: data?._id})}>
        <Card.Title style={{fontFamily: 'sans-serif'}} title={data?.Vehicle_Manufacturer} subtitle={data?.Model + " " + data?.Year} />
        {
          data?.Images == null ? <></> : <Card.Cover style={{marginHorizontal: 10}} source={{ uri: "https://backend.carologyauctions.net/images/"+ data?.Images[0]  }} />
        }
        <Card.Content>
          <Title style={{fontFamily: 'sans-serif'}}>{data?.Price} AED</Title>
          <Paragraph>{data?.Kilometers} {mileage ? "KM" : "Miles"}</Paragraph>
        </Card.Content>
        <Card.Actions>
        <Button onPress={() => navigation.navigate('ClassifiedVehicle', {id: data?._id})}>View</Button>
        {
          edit ? <Button onPress={() => navigation.navigate('EditClassifieds', {id: data?._id})}>Edit</Button> : <></>
        }
        </Card.Actions>
    </Card>
  )
}

export default CustomClassified