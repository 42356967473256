import * as React from 'react';
import { Modal, StyleSheet, View, Image, Text, TouchableOpacity, Dimensions } from 'react-native';
import { Button } from 'react-native-paper';
/* import Carousel from 'react-native-snap-carousel'; */
import Swiper from 'react-native-web-swiper';
import { useState } from 'react';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Logo from '../../../assets/images/thumbnail_Logo.png';

const CustomCarousel = ({images}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [url, setUrl] = useState(false);

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  // Calculate the height based on the desired aspect ratio (width:height = 75%:aspectRatio)
  const aspectRatio = 550 / 500; // 550 is the original height, and 500 is the original width

  return (
    <>
    {images?.length > 0 ? 
      <View style={{flex: 1, paddingTop: 50, }}>
          {/* <View style={{ flex: 1, flexDirection:'row', justifyContent: 'center', }}>
              <Carousel
                layout={"default"}
                data={images}
                sliderWidth={300}
                itemWidth={300}
                renderItem={_renderItem} />
          </View>
          <View style={styles.centeredView}>
            
        </View> */}

        <div>
        <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <Image source={{uri: url ? url : ''}} style={{width: windowWidth * 0.75, height: windowHeight * 0.75, position: 'relative'}}/>
                  <Image source={Logo} style={{position: 'absolute', width: 40, height: 40, right: 0}} resizeMode="contain" />
                  <Button onPress={() => setModalVisible(!modalVisible)} style={{position: 'absolute', left: 0}}>
                    <Ionicons name={'close'} color={'white'} size={40} />
                  </Button>
                </View>
              </View>
            </Modal>
        </div>

        <div style={{display: 'flex', width: '100%', overflowX: 'scroll'}}>
          {
            images.map((item, index) => {
              return <div onClick={() => (setModalVisible(true), setUrl(`https://backend.carologyauctions.net/images/${item}`))} key={index+'carousel'} style={{margin: 10, position: 'relative'}}>
                <Image source={{uri: `https://backend.carologyauctions.net/images/${item}`}} style={{width: 345, height: 350, position: 'relative'}}/>
                <Image source={Logo} style={{position: 'absolute', width: 40, height: 40, right: 0, top: 0}} resizeMode="contain" />
                <Button onPress={() => (setModalVisible(true), setUrl(`https://backend.carologyauctions.net/images/${item}`))} style={{position: 'absolute', top: 0}}><Ionicons name={'expand'} color={'white'} size={40} /></Button>
                </div>
            })
          }
        </div>
      
      </View>
    : null}
    </>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    padding: 4,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },

});


export default CustomCarousel;