import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Text, View, TouchableOpacity, Linking} from 'react-native';
import Theme from '../../components/Theme';
import { WebView } from 'react-native-webview';
import { Iframe } from '@bounceapp/iframe';
import Ionicons from 'react-native-vector-icons/Ionicons';

const Location = ({navigation}) => {
  const mapUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4540513722263!2d55.2783668106344!3d25.187905931984574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f697bd63ee2f7%3A0x7724c876c6de7fc1!2sCarology%20auctions%20organizing%20llc%20(branch)!5e0!3m2!1sen!2sae!4v1691183136921!5m2!1sen!2sae';
  const anotherMap = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115592.94172551295!2d55.21039975465332!3d25.12624219666559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6bd471fa31a5%3A0x90727a4d9733a84b!2sCarology%20Auctions%20Auto%20Experts%20Cityland%20Mall!5e0!3m2!1sen!2sae!4v1692769567122!5m2!1sen!2sae'

  const handleTelClick = () => {
    // Replace '1234567890' with the actual phone number you want to link to.
    const phoneNumber = '+97144416000';
    const url = `tel:${phoneNumber}`;
    Linking.openURL(url);
  };

  return (
    
    <ScrollView style={styles.root}>
      <Text style={styles.header}>Location</Text>
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4540513722263!2d55.2783668106344!3d25.187905931984574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f697bd63ee2f7%3A0x7724c876c6de7fc1!2sCarology%20auctions%20organizing%20llc%20(branch)!5e0!3m2!1sen!2sae!4v1691183136921!5m2!1sen!2sae" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      <Text style={styles.title}>Main Branch - Business Bay, Dubai</Text>
      <View style={{ flex: 1, height: 450 }}>
      <Iframe uri={mapUrl} style={{ flex: 1 }} />
    </View>

    <Text style={styles.title}>Kiosk - Cityland Mall, Dubai</Text>
    <View style={{ flex: 1, height: 450 }}>
    <Iframe uri={anotherMap} style={{ flex: 1 }} />
    </View>

<View style={{marginTop: 20}}>


    <Text style={styles.header}>Contact Us</Text>
    <TouchableOpacity style={styles.phoneBlock} onPress={handleTelClick}>
        <Ionicons name={"call"} color={"#23297A"} size={24} />
        <Text style={styles.text}>Call</Text>
    </TouchableOpacity>

    </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  root: {
    padding: 20,
    backgroundColor: 'white',
    width: "100%",
  },
  title: {
    fontSize: Theme.fontSize.title,
    textAlign: 'left',
    width: '100%',
    marginTop: 10,
    fontWeight: 'bold'
  },
  header: {
    fontSize: 18,
    color: Theme.colors.primary,
  },
  phoneBlock: {
    // Customize the width of the square block as needed
    width: '40%',
    height: 50, // Customize the height of the square block as needed
   backgroundColor: '#0073CF', // Customize the background color as needed
   justifyContent: 'center',
   alignItems: 'center',
   margin: 10,
   borderRadius: 20,
 },
    text: {
        color: "white"
    }
})

export default Location