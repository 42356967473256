import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { DataTable } from 'react-native-paper';
import Theme from '../../components/Theme';
import { AuthContext } from '../../Context/AuthContext';
import CustomButton from '../../components/CustomButton';
import CustomClassified from '../../components/CustomClassified';

const Profile = ({navigation}) => {
  const {userInfo, logout, isLoading} = useContext(AuthContext);
  const [classifieds, setClassifieds] = useState([])

  const fetchClassifieds = () => {
    fetch(`https://backend.carologyauctions.net/classifieds`)
      .then(response => {
        return response.json()
      })
      .then(data => {
          const filteredClassifieds = data.data.filter(classified => { 
            if (classified?.Username === userInfo.username) {
              return classified
            }
          })
          setClassifieds(filteredClassifieds)
        });

  }

  useEffect(()=>{
    fetchClassifieds();
  }, [])


  return (
    <ScrollView style={{ flex: 1}}>
    <View style={styles.root}>
      <DataTable>
        <DataTable.Header>
          <Text style={styles.header}>Profile</Text>
        </DataTable.Header>
        <DataTable.Row>
          <DataTable.Cell>Email</DataTable.Cell>
          <DataTable.Cell>{userInfo?.email}</DataTable.Cell>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Cell>Username</DataTable.Cell>
          <DataTable.Cell>{userInfo?.username}</DataTable.Cell>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Cell>Status</DataTable.Cell>
          <DataTable.Cell>{userInfo?.status}</DataTable.Cell>
        </DataTable.Row>
      </DataTable>
      <CustomButton
          onPress={() => navigation.navigate('ChangePassword')}
          text="Change Password"
        />
        <Text>Your Classifieds</Text>
    </View>
    
      <View style={{flexDirection: 'row', flexWrap: "wrap", justifyContent: "space-around", zIndex: -100}}>
        {
          classifieds.length > 0 ? classifieds.map((classified) => {
            return <CustomClassified style={{flexWrap: 'wrap'}} key={classified?._id} navigation={navigation} data={classified} edit={userInfo.username === classified.Username ? true : false } />
          }) : null
        }
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'white',
    width: "100%",
  }
})

export default Profile