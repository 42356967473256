import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React, {useContext, useState} from 'react'
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AuthContext } from '../../Context/AuthContext';
import { Provider as PaperProvider } from 'react-native-paper';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Theme from '../Theme';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Middleware from '../../screens/Middleware';
import Home from '../../screens/Home';
import Register from '../../screens/Register';
import SignIn from '../../screens/SignIn';
import ForgotPassword from '../../screens/ForgotPassword';
import Evaluation from '../../screens/Evaluation';
import Classifieds from '../../screens/Classifieds';
import AddClassifieds from '../../screens/AddClassifieds';
import EditClassifieds from '../../screens/EditClassifieds';
import ClassifiedVehicle from '../../screens/ClassifiedVehicle';
import VehicleCart from '../../screens/VehicleCart';
import Auctions from '../../screens/Auctions';
import Vehicle from '../../screens/Vehicle';
import Invoices from '../../screens/Invoices';
import VehicleNegotiation from '../../screens/VehicleNegotiation';
import CompletedAuctions from '../../screens/CompletedAuctions';
import Profile from '../../screens/Profile';
import ChangePassword from '../../screens/ChangePassword';
import Location from '../../screens/Location/Location';
import About from '../../screens/About';
import Toc from '../../screens/Toc';
/* 



import Notifications from '../../screens/Notifications';
 */
import logo from '../../../assets/images/logoName.png';
import { Image, Dimensions } from 'react-native';
import { useEffect } from 'react';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const screenOptions = (route, color) => {
    let iconName;
  
    switch (route.name) {
      case 'Home':
        iconName = 'home-outline';
        break;
      case 'Live Auctions':
        iconName = 'car-sport-outline';
        break;
      case 'Cart':
        iconName = 'cart-outline';
        break;
      case 'Auctioned':
        iconName = 'car-sport'
        break;
      case 'Contact':
        iconName = 'pin'
        break;
      default:
        break;
    }
    return <Ionicons name={iconName} color={color} size={24} />;
  };

const TabNavigation = () => {
    const {userInfo} = useContext(AuthContext);
    const windowWidth = Dimensions.get('window').width;
    const header = windowWidth > 800 ? 2.5 : 5

    return (
        <Tab.Navigator
            screenOptions={({route}) => ({
                tabBarIcon: ({color}) => screenOptions(route, color),
                tabBarLabelStyle: {
                    fontSize: 13,
                    bottom: 5
                },
                tabBarStyle: { 
                    position: 'absolute',
                    bottom: 25,
                    left: 20,
                    right: 20,
                    elevation: 0,
                    //backgroundColor: Theme.colors.secondary,
                    borderRadius: 15,
                    height: 60,
                    ... styles.shadow
                }
            })}>
            <Tab.Screen name="Home" component={Home} options={{headerShown: false}} />
            { userInfo?.status === "Active" ? <Tab.Screen name="Live Auctions" component={Auctions} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} /> : null }
            { userInfo?.status === "Active" ? <Tab.Screen name="Auctioned" component={CompletedAuctions} /> : null }
            { userInfo?.status === "Active" ? <Tab.Screen name="Cart" component={Invoices} /> : null }
            <Tab.Screen name="Contact" component={Location} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
        </Tab.Navigator>
    );
}
  
//React Native Vector Icons - Setup: Android and IOS simple (2022)
const Navigation = () => {
    const {userInfo} = useContext(AuthContext);
    const windowWidth = Dimensions.get('window').width;
    const [deviceId, setDeviceId] = useState("")
    const header = windowWidth > 800 ? 2.5 : 5

    const linking = {
        prefixes: ['http://192.168.0.100:19006'], // Add your linking prefixes here
        config: {
            /* configuration for matching screens with paths */
        },
    };

    return (
        <PaperProvider theme={Theme}>
            <NavigationContainer style={{width: windowWidth}} linking={linking} theme={Theme}>
                <Stack.Navigator>
                    {userInfo?.token ? (
                        <>
                            <Stack.Screen name="Middleware" component={TabNavigation} options={{headerShown: false}}/>
                            <Stack.Screen name="Middlewarere" component={TabNavigation} options={{headerShown: false}}/>
                            <Stack.Screen name="Vehicle" component={Vehicle} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            <Stack.Screen name="VehicleNegotiation" component={VehicleNegotiation} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="VehicleCart" component={VehicleCart} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            <Stack.Screen name="AddClassifieds" component={AddClassifieds} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="EditClassifieds" component={EditClassifieds} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="ClassifiedVehicle" component={ClassifiedVehicle} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="Evaluation" component={Evaluation} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="Classifieds" component={Classifieds} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="Profile" component={Profile} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="ChangePassword" component={ChangePassword} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            
                            {/* <Stack.Screen name="Notifications" component={Notifications} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} /> */}
                            <Stack.Screen name="About" component={About} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            <Stack.Screen name="Toc" component={Toc} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                        </>
                    ) : (
                        <>
                            <Stack.Screen name="SignIn" component={SignIn} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            <Stack.Screen name="Register" component={Register} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            <Stack.Screen name="ForgotPassword" component={ForgotPassword} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            <Stack.Screen name="Classifieds" component={Classifieds} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="ClassifiedVehicle" component={ClassifiedVehicle} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            <Stack.Screen name="Contact" component={Location} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} />
                            
                            {/* <Stack.Screen name="Notifications" component={Notifications} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}} /> */}
                            <Stack.Screen name="About" component={About} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                            <Stack.Screen name="Toc" component={Toc} options={{ headerTitle: (props) => (<Image style={{ width: 220, height: 50, left: windowWidth/header }} source={logo}/>)}}/>
                        </>
                    )}
                </Stack.Navigator>
            </NavigationContainer>
        </PaperProvider>
    )
}

const styles = StyleSheet.create({
    shadow: {
        shadowColor: 'black',
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.5,
        elevation: 5
    }
});

export default Navigation;