import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import Navigation from './src/components/Navigation/Navigation';
import { AuthProvider } from './src/Context/AuthContext';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';
import Toast from "react-native-toast-notifications";
import {ToastProvider} from 'react-native-toast-notifications'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

const App = gestureHandlerRootHOC(() => {
  return (
    <AuthProvider deviceId={""}>
      <Router>
      <Navigation deviceId={""} />
      <Toast ref={(ref) => global['toast'] = ref} />
      </Router>
    </AuthProvider>
  );
})

export default App;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

/* 
"rewrites": [
  { "source": "/**", "destination": "/index.html"}
] */